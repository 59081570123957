import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement } from "react";

import { useSetLanguageByCountryParam } from "../../../../core/application/localization-use-cases";
import ConnectionLost from "../../../../images/pages/leads/connection-lost.svg";
import Loader from "../../../../molecules/loader/Loader";
import PageTitle from "../../../../molecules/page-title/PageTitle";
import Footer from "../../../../organisms/footer/AlternativeFooter";
import Meta from "../../../../organisms/meta/meta";
import Navigation from "../../../../organisms/navigation/Onboarding";
import PageWrapper from "../../../../organisms/page-wrapper/PageWrapper";
import { Locale } from "../../../../settings/countries";
import useAxeptioScriptLoader from "../../../../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale } from "../../../../utils/locale-configuration-utils";
import { usePreSignature } from "../../application/signatures-use-cases";
import * as styles from "./PreSignature.module.scss";

interface PreSignatureProps {
  quoteUuid: string;
}

const PreSignature = ({ quoteUuid }: PreSignatureProps): ReactElement => {
  const { i18n, t } = useTranslation();

  const { errorGettingUrl } = usePreSignature(quoteUuid);
  const { isLanguageSet } = useSetLanguageByCountryParam();

  useAxeptioScriptLoader(i18n.language);

  return isLanguageSet ? (
    <PageWrapper>
      <Meta
        title={t("pre_signature.page_title")}
        lang={i18n.language}
        bodyClass={cn(styles.preSignature, { [styles.errorGettingUrl]: errorGettingUrl })}
        disableTranslations={false}
        description={undefined}
        alternateVersions={false}
        slug={undefined}
        noIndex={true}
      />
      <Navigation countryCodeIso={getCountryByLocale(i18n.language as Locale)} />
      <main id="main" className={styles.main}>
        {!errorGettingUrl ? (
          <Loader title={t("pre_signature.loader.title")} />
        ) : (
          <section className={styles.errorGettingUrlWrapper}>
            <ConnectionLost />
            <PageTitle
              title={t("pre_signature.getting_url_error.title")}
              subtitle={parse(t("pre_signature.getting_url_error.subtitle"))}
              adoptionClassName={styles.errorGettingUrlHeader}
            />
          </section>
        )}
      </main>
      <Footer />
    </PageWrapper>
  ) : (
    <></>
  );
};

export default PreSignature;
