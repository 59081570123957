import cn from "classnames";
import { motion } from "framer-motion";

import PawLoader from "../../atoms/paw-loader/PawLoader";
import { useAnimatedPaths } from "../../utils/hooks/useAnimatedPaths";
import * as styles from "./Loader.module.scss";

const path1 =
  "M 25.863053,127.22131 C 10.230804,78.685147 47.901342,50.358404 66.490342,31.794404 c 18.589,-18.564 49.474418,-28.6206208 76.572418,-24.3786208 26.93202,-0.6699994 55.7195,52.8233548 87.98958,49.3496208 41.89818,-4.648824 63.19309,28.060736 75.9682,50.072996 17.18015,20.11764 39.03936,74.68 22.77569,118.711 -16.26369,44.031 -70.07619,27.447 -143.97389,108.5698 C 119.1129,398.78888 98.017539,377.65347 39.330204,318.99844 -13.165812,266.53131 41.802172,183.02865 25.863053,127.22131 Z";
const path2 =
  "M329.185 259.671C311.905 299.484 288.508 335.467 269.919 354.031C251.33 372.595 215.798 389.028 188.7 384.786C161.603 380.543 113.184 331.216 105.357 329.06C97.5305 326.905 51.8287 294.312 29.3888 278.987C6.94891 263.661 -9.65056 204.307 6.61311 160.276C22.8768 116.245 76.6893 132.829 150.587 51.7062C224.485 -29.4167 268.609 -9.46423 281.225 76.9017C293.841 163.268 357.367 194.742 329.185 259.671Z";
const path3 =
  "M331.4 181.3c5.2 43 3.2 86-3.4 111.3-6.6 25.5-8 53-33.6 63-25.5 10-102.9 21.2-135.3 18.8-37.9 1.2-65-19.9-92-21.7-47.5-1.9-56-47.7-64.2-94-8.3-46.1 46.5-59 69.2-166.4C94.8-15.1 142.9-20.2 197.5 47.9c52.9 84.2 127.1 25.5 133.9 133.4Z";
const paths = [path1, path2, path3, path1];

interface LoaderProps {
  title: string;
  adoptionClassName?: string;
  hasAnimatedBackground?: boolean;
}

const Loader = ({
  adoptionClassName,
  title,
  hasAnimatedBackground = true,
}: LoaderProps): JSX.Element => {
  const path = useAnimatedPaths(paths);

  return (
    <div className={cn("ml-loader", styles.loader, adoptionClassName)}>
      <h1 className={styles.title}>{title}</h1>
      {hasAnimatedBackground && (
        <svg viewBox="0 0 337 386">
          <g>
            <motion.path initial={false} d={path} fill={"var(--o-200-color)"} />
          </g>
        </svg>
      )}
      <PawLoader />
    </div>
  );
};

export default Loader;
