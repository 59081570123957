import { useTranslation } from "gatsby-plugin-react-i18next";
import { useEffect, useState } from "react";

import { CountryCode } from "../../settings/countries";
import { getBestLocale, getCurrentURLSearchParams } from "../../utils/browser-features";
import { getLocaleByCountry } from "../../utils/locale-configuration-utils";

export const useSetLanguageByCountryParam = (): { isLanguageSet: boolean } => {
  const { i18n } = useTranslation();

  const [isLanguageSet, setIsLanguageSet] = useState<boolean>(false);

  useEffect(() => {
    const currentURLSearchParams = getCurrentURLSearchParams();
    const countryParam = currentURLSearchParams?.get("country")?.toUpperCase();

    const locale = countryParam ? getLocaleByCountry(countryParam as CountryCode) : getBestLocale();

    void i18n.changeLanguage(locale);
    setIsLanguageSet(true);
  }, [i18n]);

  return { isLanguageSet };
};
