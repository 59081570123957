import cn from "classnames";
import { motion, useReducedMotionConfig, Variants } from "framer-motion";
import { useState } from "react";

import * as styles from "./PawLoader.module.scss";

const draw: Variants = {
  hidden: { pathLength: 0, strokeLinecap: "inherit" },
  visible: (i: number) => {
    const duration = 1;
    const delay = i * (duration - 0.5);

    return {
      pathLength: 1,
      strokeLinecap: "round",
      transition: {
        pathLength: { delay, type: "spring", duration, bounce: 0 },
        strokeLinecap: { delay },
      },
    };
  },
};

interface PawProps {
  animated?: boolean;
  onAnimationComplete?: () => void;
}

const Paw = ({ animated = false, onAnimationComplete = () => {} }: PawProps): JSX.Element => {
  return (
    <motion.svg
      viewBox="0 0 62 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial="hidden"
      animate="visible"
    >
      <motion.path
        d="M51.4139 38.6775L51.4205 38.6809L51.427 38.6842L52.8411 39.4042L53.8394 40.1485L54.6714 41.0156L55.0062 41.4635L55.6035 42.2993L55.8442 42.689L56.3223 43.828L56.5603 44.5033L56.8939 45.7484L57.0389 46.8901L56.9851 48.3516L56.6856 49.9348L56.2033 51.1366L55.3921 52.5417L54.5934 53.4933L53.3116 54.565L52.3401 55.132L51.7819 55.325L50.9563 55.4951L49.9736 55.5583L48.8157 55.3916L47.4561 55.0338L45.8072 54.4944L45.6985 54.4589L45.5864 54.4359L43.5836 54.0261L43.5664 54.0226L43.5492 54.0194L41.9126 53.7142L41.6804 53.6709L41.4445 53.6829L40.5108 53.7303L40.4563 53.7331L40.4021 53.7388L39.1528 53.8708L38.9343 53.8938L38.726 53.9638L37.7335 54.2972L37.5446 54.3606L37.3719 54.4601L36.4936 54.9662L36.3447 55.0519L36.2127 55.1618L35.5091 55.7474L34.6335 56.4473L34.5335 56.5273L34.4445 56.6193L33.6566 57.4341L32.7767 58.3282L31.8277 59.2464L31.7293 59.3416L31.6449 59.4493L31.3668 59.8037L30.6428 60.4186L29.2269 61.1629L27.6372 61.5362L26.6325 61.5644L24.9966 61.2786L23.5453 60.7875L22.3849 60.1178L21.4549 59.4137L20.4284 58.2558L19.558 56.9068L19.1687 56.0128L18.7921 54.3921L18.6103 52.816L18.5553 51.1228L18.8279 49.7905L19.2738 48.5997L19.4195 48.393L19.4485 48.3518L19.4755 48.3092L22.5618 43.426L22.6471 43.2909L22.71 43.144L23.6245 41.0059L23.6334 40.9852L23.6418 40.9642L24.3713 39.146L24.4336 38.9908L24.4692 38.8274L24.9546 36.6016L24.9599 36.5777L24.9645 36.5537L25.4333 34.1198L25.8337 32.5695L26.2314 31.3467L27.0045 30.0077L28.3667 28.4125L29.8561 27.3703L30.9332 26.9257L32.1961 26.5873L33.3651 26.4319L34.6624 26.5268L35.6378 26.6807L36.4717 27.005L37.1987 27.3796L37.9204 27.7962L39.0953 28.7047L40.2453 29.8024L41.2235 30.8647L42.0869 31.8807L42.1188 31.9181L42.1524 31.954L43.5717 33.4667L43.6376 33.5371L43.7101 33.6007L44.948 34.6885L44.9898 34.7252L45.0336 34.7596L46.318 35.7674L46.388 35.8223L46.4626 35.8708L47.9359 36.8286L48.003 36.8722L48.0734 36.9103L49.5762 37.7243L49.5921 37.733L49.6082 37.7413L51.4139 38.6775Z"
        stroke="var(--paw-stroke-color, var(--br-400-color))"
        variants={animated ? draw : undefined}
        custom={5}
        onAnimationComplete={onAnimationComplete}
      />
      <motion.path
        d="M59 22.5C59 25.0307 58.2643 27.2383 57.1758 28.7621C56.0847 30.2898 54.7575 31 53.5 31C52.2425 31 50.9153 30.2898 49.8242 28.7621C48.7357 27.2383 48 25.0307 48 22.5C48 19.9693 48.7357 17.7617 49.8242 16.2379C50.9153 14.7102 52.2425 14 53.5 14C54.7575 14 56.0847 14.7102 57.1758 16.2379C58.2643 17.7617 59 19.9693 59 22.5Z"
        stroke="var(--paw-stroke-color, var(--br-400-color))"
        variants={animated ? draw : undefined}
        custom={4}
      />
      <motion.path
        d="M42.5119 10.6852C42.9167 13.1833 42.5435 15.4802 41.7128 17.1584C40.8799 18.8409 39.6835 19.7543 38.4421 19.9554C37.2008 20.1565 35.7772 19.6677 34.4557 18.3342C33.1376 17.0041 32.0583 14.9425 31.6535 12.4444C31.2488 9.94627 31.622 7.64939 32.4527 5.97112C33.2855 4.28862 34.482 3.37531 35.7233 3.17419C36.9647 2.97308 38.3883 3.46189 39.7097 4.79537C41.0279 6.12549 42.1072 8.18704 42.5119 10.6852Z"
        stroke="var(--paw-stroke-color, var(--br-400-color))"
        variants={animated ? draw : undefined}
        custom={3}
      />
      <motion.path
        d="M24.2076 13.5689C25.1261 15.927 25.2417 18.2511 24.7805 20.0661C24.3182 21.8856 23.3393 23.029 22.1675 23.4854C20.9958 23.9418 19.5014 23.7617 17.9302 22.7342C16.3629 21.7094 14.8761 19.9193 13.9576 17.5611C13.0392 15.203 12.9235 12.8788 13.3847 11.0639C13.847 9.2444 14.8259 8.10096 15.9977 7.64456C17.1695 7.18817 18.6639 7.36829 20.2351 8.39575C21.8023 9.42063 23.2891 11.2107 24.2076 13.5689Z"
        stroke="var(--paw-stroke-color, var(--br-400-color))"
        variants={animated ? draw : undefined}
        custom={2}
      />
      <motion.path
        d="M15.9244 31.5103C16.8429 33.8684 16.9585 36.1925 16.4973 38.0075C16.035 39.827 15.0561 40.9704 13.8843 41.4268C12.7126 41.8832 11.2182 41.7031 9.64695 40.6756C8.07969 39.6508 6.5929 37.8607 5.67442 35.5025C4.75595 33.1444 4.64029 30.8203 5.10147 29.0053C5.56382 27.1858 6.5427 26.0424 7.71448 25.586C8.88626 25.1296 10.3807 25.3097 11.9519 26.3372C13.5191 27.362 15.0059 29.1521 15.9244 31.5103Z"
        stroke="var(--paw-stroke-color, var(--br-400-color))"
        variants={animated ? draw : undefined}
        custom={1}
      />
    </motion.svg>
  );
};

const PawLoader = (): JSX.Element => {
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [animationCounter, setAnimationCounter] = useState(0);
  const reduceMotion = useReducedMotionConfig();
  const onAnimationComplete = () => {
    if (animationCounter < 10) {
      return setAnimationCounter(animationCounter + 1);
    }

    setAnimationCompleted(true);
  };

  return (
    <span
      className={cn(styles.pawLoader, "at-paw-loader", {
        [styles.pawLoaderCompleted as string]: animationCompleted,
      })}
    >
      <Paw />
      <Paw
        key={animationCounter}
        animated={!reduceMotion}
        onAnimationComplete={onAnimationComplete}
      />
    </span>
  );
};

export default PawLoader;
