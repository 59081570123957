import { useCallback, useEffect, useState } from "react";

import { getWindow } from "../../../utils/browser-features";
import { getSignatureUrl } from "../data/signatures-repository";

const useGetSignatureUrl = (): ((quoteUuid: string) => Promise<Record<string, string>>) => {
  return useCallback((quoteUuid: string) => {
    return getSignatureUrl(quoteUuid);
  }, []);
};

export const usePreSignature = (quoteUuid: string): { errorGettingUrl: boolean } => {
  const getSignatureUrl = useGetSignatureUrl();

  const [errorGettingUrl, setErrorGettingUrl] = useState<boolean>(false);

  useEffect(() => {
    if (!quoteUuid) {
      return;
    }

    const fetchSignatureUrl = async () => {
      try {
        const { url } = await getSignatureUrl(quoteUuid);
        const w = getWindow();

        if (w !== null) {
          w.location.href = url;
        }
      } catch (error) {
        setErrorGettingUrl(true);
      }
    };

    void fetchSignatureUrl();
  }, [quoteUuid, getSignatureUrl]);

  return {
    errorGettingUrl,
  };
};
